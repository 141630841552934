import { Popconfirm } from "antd";
import { FormattedMessage } from "react-intl";

export default function RemoveButton(props) {
  const { onClick, className, ...btnProps } = props;
  return (
    <Popconfirm
      title="Are you sure? Want to delete this?"
      onConfirm={onClick}
      okText="Yes"
      cancelText="No"
    >
      <button
        type="button"
        className={`btn btn-danger ${className}`}
        {...btnProps}
      >
        <i className={`glyphicon glyphicon-remove`} />
      </button>
    </Popconfirm>
  );
}
