import { Button, Modal } from "antd";
import { useState } from "react";

import TextArea from "antd/lib/input/TextArea";

export default function TextAreaWidget(props) {
  const [openHTMLModal, setOpenModal] = useState(false);

  return (
    <>
      <Button
        // style={{ display: "block" }}
        style={{
          float: "right",
          height: "30px",
        }}
        onClick={() => setOpenModal(!openHTMLModal)}
      >
        Click here to enter text
      </Button>
      <TextArea
        value={props.value}
        style={{ marginTop: "10px" }}
        onChange={(e) => props.onChange(e.target.value)}
      />
      <Modal
        open={openHTMLModal}
        title="Please enter text"
        onCancel={() => setOpenModal(!openHTMLModal)}
        width="70%"
        footer={null}
      >
        <TextArea
          value={props.value}
          style={{ marginTop: "10px", height: "200px" }}
          onChange={(e) => props.onChange(e.target.value)}
        />
      </Modal>
    </>
  );
}
