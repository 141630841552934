import React, { CSSProperties } from "react";
import { Card, Space } from "antd";

/** The `ArrayFieldItemTemplate` component is the template used to render an items of an array.
 *
 * @param props - The `ArrayFieldTemplateItemType` props for the component
 */
export default function ArrayFieldItemTemplate(props) {
  const {
    children,
    className,
    disabled,
    hasToolbar,
    hasMoveDown,
    hasMoveUp,
    hasRemove,
    index,
    onDropIndexClick,
    onReorderClick,
    readonly,
    registry,
    uiSchema,
  } = props;
  const { MoveDownButton, MoveUpButton, RemoveButton } =
    registry.templates.ButtonTemplates;
  const btnStyle = {
    flex: 1,
    paddingLeft: 6,
    paddingRight: 6,
    fontWeight: "bold",
  };
  return (
    <div className={className}>
      <Card style={{ marginBottom: "1rem", borderColor: "#e2e2e2" }}>
        <Space direction="vertical" style={{ width: "100%" }}>
          {hasToolbar && (
            <div
              className="col-xs-3 pull-right array-item-toolbox"
              style={{ marginTop: ".5rem" }}
            >
              <div
                className="btn-group"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                {(hasMoveUp || hasMoveDown) && (
                  <MoveUpButton
                    style={btnStyle}
                    disabled={disabled || readonly || !hasMoveUp}
                    onClick={onReorderClick(index, index - 1)}
                    uiSchema={uiSchema}
                  />
                )}
                {(hasMoveUp || hasMoveDown) && (
                  <MoveDownButton
                    style={btnStyle}
                    disabled={disabled || readonly || !hasMoveDown}
                    onClick={onReorderClick(index, index + 1)}
                    uiSchema={uiSchema}
                  />
                )}
                {hasRemove && (
                  <RemoveButton
                    style={btnStyle}
                    disabled={disabled || readonly}
                    onClick={onDropIndexClick(index)}
                    uiSchema={uiSchema}
                  />
                )}
              </div>
            </div>
          )}
          <div className={"col-xs-12"}>{children}</div>
        </Space>
      </Card>
    </div>
  );
}
