import { Button, Modal } from "antd";
import { useEffect, useState } from "react";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import TextArea from "antd/lib/input/TextArea";
import { editorOptions } from "../constants";

export default function HTMLWidget(props) {
  const [openHTMLModal, setOpenModal] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (!openHTMLModal) {
      const contentBlock = htmlToDraft(props.value || "");
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    }
  }, [props.value]);

  const onChange = (e) => {
    props.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };
  return (
    <>
      <Button
        // style={{ display: "block" }}
        style={{
          float: "right",
          height: "30px",
        }}
        onClick={() => setOpenModal(!openHTMLModal)}
      >
        Click here to enter Rich text
      </Button>
      <TextArea
        value={props.value}
        style={{ marginTop: "10px" }}
        onChange={(e) => props.onChange(e.target.value)}
      />
      <div>
        {openHTMLModal && (
          <Modal
            open={openHTMLModal}
            title="Please enter text"
            onCancel={() => setOpenModal(!openHTMLModal)}
            width="80%"
            footer={null}
          >
            <div style={{ height: "60vh" }}>
              <Editor
                editorState={editorState}
                editorClassName="html-editor"
                toolbar={editorOptions}
                onChange={onChange}
                onEditorStateChange={setEditorState}
              />
            </div>
          </Modal>
        )}
      </div>
    </>
  );
}
