import React, { useState } from "react";
import { getTemplate, getUiOptions } from "@rjsf/utils";
import { Collapse } from "antd";

import { DownOutlined, LeftOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

/** The `ArrayFieldTemplate` component is the template used to render all items in an array.
 *
 * @param props - The `ArrayFieldTemplateItemType` props for the component
 */
export default function ArrayFieldTemplate(props) {
  const [activekey, setActiveKey] = useState(["1"]);
  const {
    canAdd,
    className,
    disabled,
    idSchema,
    uiSchema,
    items,
    onAddClick,
    readonly,
    registry,
    required,
    schema,
    title,
  } = props;
  const uiOptions = getUiOptions(uiSchema);
  const ArrayFieldDescriptionTemplate = getTemplate(
    "ArrayFieldDescriptionTemplate",
    registry,
    uiOptions
  );
  const ArrayFieldItemTemplate = getTemplate(
    "ArrayFieldItemTemplate",
    registry,
    uiOptions
  );
  const ArrayFieldTitleTemplate = getTemplate(
    "ArrayFieldTitleTemplate",
    registry,
    uiOptions
  );
  const returnfieldtitle = () => (
    <ArrayFieldTitleTemplate
      idSchema={idSchema}
      title={uiOptions.title || title}
      required={required}
      schema={schema}
      uiSchema={uiSchema}
      registry={registry}
    />
  );
  // Button templates are not overridden in the uiSchema
  const {
    ButtonTemplates: { AddButton },
  } = registry.templates;
  const renderIcon = () =>
    !activekey.length ? <LeftOutlined /> : <DownOutlined />;
  return (
    <fieldset className={className} id={idSchema.$id}>
      <Collapse
        defaultActiveKey={["1"]}
        ghost
        expandIconPosition="end"
        onChange={setActiveKey}
        expandIcon={renderIcon}
      >
        <Panel header={returnfieldtitle()} key="1">
          <ArrayFieldDescriptionTemplate
            idSchema={idSchema}
            description={uiOptions.description || schema.description}
            schema={schema}
            uiSchema={uiSchema}
            registry={registry}
          />
          <div className="row array-item-list">
            {items &&
              items.map(({ key, ...itemProps }) => (
                <ArrayFieldItemTemplate key={key} {...itemProps} />
              ))}
          </div>
          {canAdd && (
            <AddButton
              className="array-item-add"
              onClick={onAddClick}
              disabled={disabled || readonly}
              uiSchema={uiSchema}
            />
          )}
        </Panel>
      </Collapse>
    </fieldset>
  );
}
