import React, { useContext, useEffect, useRef, useState } from "react";
import Form from "@rjsf/core";
import { diff } from "json-diff";
import { Button, Input, notification, Typography } from "antd";
import validator from "@rjsf/validator-ajv8";
import "./Home.scss";
import { post_pages, update_page } from "../request";
import Context from "../Context";
import * as customWidgets from "../Customs";
const { Title } = Typography;
const widgets = {
  HTMLWidget: customWidgets.HTMLWidget,
  TextAreaWidget: customWidgets.TextAreaWidget,
  DateTimeWidget: customWidgets.DateTimeWidget,
};

const templates = {
  ButtonTemplates: { RemoveButton: customWidgets.RemoveButton },
  ArrayFieldTemplate: customWidgets.ArrayFieldTemplate,
  ArrayFieldItemTemplate: customWidgets.ArrayFieldItemTemplate,
  // ObjectFieldTemplate: customWidgets.ObjectFieldTemplate,
};

function Home(props) {
  const { token } = useContext(Context);
  const { datatype, data, setEditObject, refreshSchema } = props;
  const formEng = useRef();
  const formHin = useRef();
  const formGuj = useRef();
  const [engFormData, setEngFormData] = useState({});
  const [gujFormData, setGujFormData] = useState({});
  const [hinFormData, setHinFormData] = useState({});
  const [titleFormData, setTitleFormData] = useState("");
  const [schema, setSchema] = useState({});
  const [uischema, setUISchema] = useState({});

  useEffect(() => {
    if (data.attributes?.title) {
      setEngFormData(data.attributes?.JSON_ENG);
      setGujFormData(data.attributes?.JSON_GUJ);
      setHinFormData(data.attributes?.JSON_HIN);
      setTitleFormData(data.attributes?.title);
    }
  }, [data]);

  useEffect(() => {
    if (datatype.attributes?.jsonschema) {
      setSchema(datatype.attributes?.jsonschema);
      setUISchema(datatype.attributes?.uischema);
    }
  }, [datatype]);

  const setdiff = async (newd, oldd, lang) => {
    console.log(newd);
    console.log(oldd);
    try {
      const mapper = {
        GUJ: [{ ...gujFormData }, setGujFormData, {}],
        ENG: [{ ...engFormData }, setEngFormData, {}],
        HIN: [{ ...hinFormData }, setHinFormData, {}],
      };
      const di = diff(oldd, newd);
      console.log(di);
      if (di) {
        for (const [mkey, mvalue] of Object.entries(mapper)) {
          // ignore for lang other than the lang event happened
          if (mkey !== lang) {
            for (const [key, value] of Object.entries(newd)) {
              if (
                Object.keys(di).findIndex((item) => item.includes(key)) > -1
              ) {
                // AND ONLY ARRAY TYPE
                if(Array.isArray(value)){
                
                  // check existing data is not of same length of new change
                  const newLength = mvalue[0][key]
                    ? mvalue[0][key].length
                    : value.length;
                  
                  // if new object is added
                  if (!mvalue[0][key]) {
                    
                    mvalue[0][key] = Array.apply(null, Array(newLength)).map(
                      () => {
                        return undefined;
                      }
                    );
                  } else if (value.length === 0) {
                    mvalue[0][key] = [];
                  }
                  // if key is present but change in length
                  else if (newLength !== value.length) {
                    if (newLength > value.length) {
                      for (const index in di[key]) {
                        const divalue = di[key][index];
  
                        // if diff shows item is - i.e delete
                        if (divalue && divalue[0] === "-") {
                          mvalue[0][key].splice(index, 1);
                        }
                      }
                    } else {
                      const diffarray = Array.apply(
                        null,
                        Array(value.length - newLength)
                      ).map(() => {
                        return undefined;
                      });
                      mvalue[0][key] = mvalue[0][key].concat(diffarray);
                    }
                  }
  
                  //  or reordered
                  else if (newLength === value.length) {
                    for (const index in di[key]) {
                      if (index - 2 >= 0) {
                        const dipreviousvalue = di[key][index - 2];
                        const divalue = di[key][index];
  
                        // check if the value at index-2 was added, then treat it as reorder
                        if (
                          divalue &&
                          divalue[0] === "-" &&
                          dipreviousvalue &&
                          dipreviousvalue[0] === "+"
                        ) {
                          mvalue[0][key].splice(
                            index - 2,
                            0,
                            mvalue[0][key][index - 1]
                          );
                          mvalue[0][key].splice(index, 1);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        for (const [key, value] of Object.entries(mapper)) {
          const va = value[1];
          const val = value[2];
          value !== lang && va({ ...value[0], ...val });
        }
      }
    } catch (error) {
      console.log("error in setdiff", error);
    }
  };
  const onSubmit = () => {
    try {
      // if (
      //   !formEng.current.validateForm() ||
      //   !formGuj.current.validateForm() ||
      //   !formHin.current.validateForm()
      // ) {
      //   return;
      // }
      if (!titleFormData?.length) {
        notification.error({
          message: "Please enter Title!",
        });
        return;
      }
    } catch (error) {
      console.log(error);
      return;
    }
    Object.keys(data).length ? updateData() : postData();
  };
  const reset = () => {
    setEngFormData({});
    setGujFormData({});
    setHinFormData({});
    setTitleFormData("");
    setEditObject({});
  };
  const postData = async () => {
    const responsedata = await post_pages(
      {
        JSON_HIN: hinFormData,
        JSON_GUJ: gujFormData,
        JSON_ENG: engFormData,
        title: titleFormData,
        datatype: datatype.id,
      },
      token
    );
    responsedata.status === 200
      ? notification.success({
          message: "Added successfully",
        })
      : notification.error({
          message: "Something failed",
        });
    reset();
  };
  const updateData = async () => {
    const responsedata = await update_page(
      data.id,
      {
        JSON_HIN: hinFormData,
        JSON_GUJ: gujFormData,
        JSON_ENG: engFormData,
        title: titleFormData,
      },
      token
    );
    responsedata.status === 200
      ? notification.success({
          message: "Updated successfully",
        })
      : notification.error({
          message: "Something failed",
        });
    reset();
  };

  // console.log(engFormData, gujFormData, hinFormData);
  return (
    <React.Fragment>
      <div className="home-header">
        <Title>Title</Title>
        <Input
          maxLength={50}
          style={{
            width: "20%",
          }}
          value={titleFormData}
          onChange={(e) => setTitleFormData(e.target.value)}
        />
      </div>
      <div className="home-outer-container">
        <div className="home-container">
          <Title>English</Title>
          <Form
            className="rjsfform"
            ref={formEng}
            schema={schema}
            uiSchema={uischema}
            autocomplete="on"
            formData={engFormData}
            validator={validator}
            formContext={{ hideAll: false }}
            widgets={widgets}
            templates={templates}
            onChange={(data) => {
              setdiff(data.formData, engFormData, "ENG");
              setEngFormData(data.formData);
            }}
          />
        </div>
        <div className="home-container">
          <Title>Gujarati</Title>
          <Form
            className="rjsfform"
            ref={formGuj}
            schema={schema}
            uiSchema={uischema}
            autocomplete="on"
            formData={gujFormData}
            validator={validator}
            formContext={{ hideAll: false }}
            widgets={widgets}
            templates={templates}
            onChange={(data) => {
              setdiff(data.formData, gujFormData, "GUJ");
              setGujFormData(data.formData);
            }}
          />
        </div>
        <div className="home-container">
          <Title>Hindi</Title>
          <Form
            className="rjsfform"
            ref={formHin}
            schema={schema}
            uiSchema={uischema}
            autocomplete="on"
            formData={hinFormData}
            validator={validator}
            formContext={{ hideAll: false }}
            widgets={widgets}
            templates={templates}
            onChange={(data) => {
              setdiff(data.formData, hinFormData, "HIN");
              setHinFormData(data.formData);
            }}
          />
        </div>
      </div>
      <Button type="primary" className="submit-btn" onClick={onSubmit}>
        Submit
      </Button>
    </React.Fragment>
  );
}
export default Home;
