import React, { useContext, useEffect, useState } from "react";
import { get_dataTypes, get_pages } from "../request";
import SideBar from "../Sidebar/Sidebar";
import HomeTab from "./HomeTab";
import "./Home.scss";
import Pages from "./Pages";
import {
  CreditCardOutlined,
  LeftOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Button, Card, Space, Tabs } from "antd";
import Home from "./Home";
import Context from "../Context";
import Preview from "../Preview/Preview";
import PreviewContainer from "../Preview/PreviewContainer";
import Search from "antd/lib/input/Search";

const HomeContainer = () => {
  const { token } = useContext(Context);
  const [edit, setEdit] = useState(false);
  const [preview, setPreview] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [editObject, setEditObject] = useState({});
  const [datatypes, setFormData] = useState([]);
  const [pages, setPages] = useState([]);
  const [selectedKey, setSelectedKey] = useState(1);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    getData();
    getPages();
  }, [edit]);

  useEffect(() => {
    getPages();
  }, [selectedKey]);

  const changeSelectedKey = (event) => {
    const key = event.key;
    if (key !== selectedKey) {
      setSelectedKey(key);
      reset();
    }
  };
  const reset = () => {
    setEditId({});
    setPreviewId({});
  };
  const setEditId = (object) => {
    setEdit(!!Object.keys(object).length);
    setEditObject(object);
  };
  const setPreviewId = (object) => {
    setPreview(!!Object.keys(object).length);
    setEditObject(object);
  };

  const getData = async () => {
    const responsedata = await get_dataTypes(token);
    setFormData(responsedata.data.data);
  };

  const getPages = async () => {
    setPageLoading(true);
    const responsedata = await get_pages({ datatype: selectedKey }, token);
    setPages(responsedata.data.data);
    setPageLoading(false);
  };

  const renderDataFill = () => {
    return (
      <div>
        {edit || preview ? (
          <>
            <Button type="link" icon={<LeftOutlined />} onClick={reset}>
              Back to List
            </Button>
            {edit && (
              <Button
                style={{ float: "right", marginRight: "10%" }}
                icon={<ReloadOutlined />}
                onClick={getData}
              >
                Refresh Schema
              </Button>
            )}
          </>
        ) : (
          <Space size={8} style={{ float: "right", marginRight: "10%" }}>
            <Search
              placeholder="Search Pages by Title"
              allowClear
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <Button icon={<CreditCardOutlined />} onClick={() => setEdit(true)}>
              Create
            </Button>
          </Space>
        )}

        {edit ? (
          <Home
            datatype={datatypes.find((i) => i.id == selectedKey)}
            data={editObject}
            setEditObject={setEditId}
            refreshSchema={getData}
          />
        ) : preview ? (
          <Preview
            datatype={datatypes.find((i) => i.id == selectedKey)}
            data={editObject}
          />
        ) : (
          <Pages
            pages={pages.filter((item) => item?.attributes?.title.includes(searchText))}
            setPages={setPages}
            loading={pageLoading}
            setEditId={setEditId}
            setPreview={setPreviewId}
          />
        )}
      </div>
    );
  };

  const renderPreview = () => {
    return (
      <Space direction="vertical" size={16}>
        {pages.map((data) => {
          return (
            <Card>
              <Space size={4}>
                <PreviewContainer
                  data={data.attributes.JSON_ENG}
                  width="25vw"
                />
                <PreviewContainer
                  data={data.attributes.JSON_GUJ}
                  width="25vw"
                />
                <PreviewContainer
                  data={data.attributes.JSON_HIN}
                  width="25vw"
                />
              </Space>
            </Card>
          );
        })}
      </Space>
    );
  };
  const items = [
    {
      label: "Form View",
      key: "item-1",
      children: renderDataFill(),
    }, // remember to pass the key prop
    { label: "Preview", key: "item-2", children: renderPreview() },
  ];
  return (
    <div className="content">
      <div style={{ paddingTop: "180px" }}>
        <SideBar
          formData={datatypes}
          selectedKey={selectedKey}
          changeSelectedKey={changeSelectedKey}
        />
      </div>
      <div className="sidebarOverlap">
        {renderDataFill()}
        {/* <Tabs
          centered
          items={items}
          // onTabClick={onTabClick}
          style={{ padding: "10px 20px" }}
        /> */}
      </div>
    </div>
  );
};
export default HomeContainer;
