import React, { useContext, useEffect, useState } from "react";
import Editor from "@monaco-editor/react";
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Space,
  Table,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { get_dataTypes, update_dataType } from "../request";
import "./List.scss";
import Context from "../Context";
import Title from "antd/lib/typography/Title";

function formatJSON(val = {}) {
  try {
    const res = JSON.parse(val);
    return JSON.stringify(res, null, 2);
  } catch {
    const errorJson = {
      error: `couldn't parse the json`,
    };
    return JSON.stringify(errorJson, null, 2);
  }
}

const DataTypeForm = ({ open, onCreate, onCancel, editData }) => {
  const [form] = Form.useForm();
  const [jsonschema, setJSONSchema] = useState({});
  const [uischema, setUIJSONSchema] = useState({});
  const [defaultValues, setDefaultValues] = useState({});

  const onReset = () => {
    form.resetFields();
    onCancel();
  };

  useEffect(() => {
    if (editData) {
      setJSONSchema(editData.attributes?.jsonschema);
      setDefaultValues({
        name: editData.attributes?.name,
        key: editData.attributes?.key,
        // jsoneditor: editData.jsonschema,
      });
    }
  }, [editData]);

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [form, defaultValues]);

  const onValidateError = (obj) => {
    try {
      JSON.parse(JSON.stringify(obj));
      return false;
    } catch {
      console.log(typeof obj);
      return true;
    }
  };
  const onChange = (obj) => console.log(obj);

  return (
    <Modal
      destroyOnClose={true}
      open={open}
      title="Update a datatype"
      okText="Update"
      cancelText="Cancel"
      onCancel={onReset}
      width="70%"
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            if (onValidateError(jsonschema)) {
              return notification.error({
                message: "JSON Schema is not valid",
              });
            }
            if (onValidateError(uischema)) {
              return notification.error({
                message: "UI Schema is not valid",
              });
            }
            values.jsonschema = jsonschema;
            values.uischema = uischema;
            form.resetFields();

            onCreate(values);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={defaultValues}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please input the name of datatype!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="key"
          label="Key"
          rules={[
            {
              required: true,
              message: "Please input the key of datatype!",
            },
          ]}
        >
          <Input onKeyDown={onChange} />
        </Form.Item>
      </Form>
      {/* <ReactJson src={editData?.attributes?.jsonschema || {}} /> */}
      <Space size={8} direction={"vertical"} style={{ width: "100%" }}>
        <Space size={4} direction={"vertical"} style={{ width: "100%" }}>
          <Title level={4}>JSON Schema</Title>
          <Editor
            height="300px"
            width="100%"
            language="json"
            theme="vs-dark"
            value={formatJSON(
              JSON.stringify(editData?.attributes?.jsonschema || {})
            )}
            onChange={(val) => setJSONSchema(val)}
          />
        </Space>
        <Space size={4} direction={"vertical"} style={{ width: "100%" }}>
          <Title level={4}>UI Schema</Title>
          <Editor
            height="300px"
            width="100%"
            language="json"
            theme="vs-dark"
            value={formatJSON(
              JSON.stringify(editData?.attributes?.uischema || {})
            )}
            onChange={(val) => setUIJSONSchema(val)}
          />
        </Space>
      </Space>
    </Modal>
  );
};
function List() {
  const { token } = useContext(Context);
  const [formData, setFormData] = useState([]);
  const [editData, setEditData] = useState({});
  const [editId, setEditId] = useState(0);

  useEffect(() => {
    getDataTypes();
  }, []);

  useEffect(() => {
    if (editId) {
      setEditData(formData.find((item) => item.id == editId));
    }
  }, [editId]);

  const resetEdit = () => {
    setEditData({});
    setEditId(0);
  };

  const onUpdate = async (body) => {
    try {
      await update_dataType(editId, body, token);
      notification.success({ message: "Updated Successfully" });
      resetEdit();
    } catch (error) {
      const message =
        error?.response?.status === 403
          ? "You don't have permission to update data points!"
          : "Couldn't export json";
      notification.error({ message });
    }
  };

  const getDataTypes = async () => {
    const responsedata = await get_dataTypes(token);
    setFormData(responsedata.data.data);
  };

  const deleteDatatype = async (dtid) => {
    setFormData((formData) => formData.filter((item) => item.id !== dtid));
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      render: (value, record) => `${record.attributes.name}`,
    },
    {
      title: "Key",
      key: "key",
      render: (value, record) => `${record.attributes.key}`,
    },
    {
      title: "Updated At",
      width: "30%",
      key: "updated_at",
      render: (value, record) =>
        `${new Date(record.attributes.updatedAt).toLocaleString()}`,
    },
    {
      title: "Action",
      key: "action",
      width: "20%",
      render: (value, record) => (
        <Space direction="horizontal" size={8}>
          <Button
            icon={<EditOutlined />}
            onClick={() => setEditId(record.id)}
          />
          <Popconfirm
            title="Are you sure to delete this task?"
            onConfirm={() => deleteDatatype(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <React.Fragment>
      <DataTypeForm
        open={editId && Object.keys(editData).length}
        editData={editData}
        onCancel={resetEdit}
        onCreate={onUpdate}
      />
      <div className="list-container">
        <Table dataSource={formData} columns={columns} />
        {/* {editId && Object.keys(editData).length ? (
          <DataTypeForm
            editData={editData}
            onCancel={resetEdit}
            onCreate={onUpdate}
          />
        ) : (
          <Table dataSource={formData} columns={columns} />
        )} */}
      </div>
    </React.Fragment>
  );
}
export default List;
