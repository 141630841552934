import React from "react";
import { Layout } from "antd";
import { Menu } from "antd";
import _ from "lodash";
import "./Sidebar.scss";

const Menus = ({ topics, selectedKey, changeSelectedKey }) => {
  const styledTopics = [];
  _.sortBy(topics, "id").forEach((topic, index) => {
    styledTopics.push(
      <Menu.Item key={topic.id} onClick={changeSelectedKey}>
        {topic.attributes.name}
      </Menu.Item>
    );
  });

  return (
    <Menu mode="inline" selectedKeys={[selectedKey.toString()]}>
      {styledTopics}
    </Menu>
  );
};
const SideBar = ({ formData, selectedKey, changeSelectedKey }) => {
  return (
    <Layout.Sider
      className="sidebar"
      theme="light"
      trigger={null}
      style={{ maxWidth: "25%" }}
    >
      <Menus
        topics={formData}
        selectedKey={selectedKey}
        changeSelectedKey={changeSelectedKey}
      />
    </Layout.Sider>
  );
};
export default SideBar;
