import React, { useContext } from "react";
import { Button, notification, Popconfirm, Tabs } from "antd";
import "./Topbar.scss";
import HomeContainer from "../Home/HomeContainer";
import List from "../List/List";
import Context from "../Context";
import { publishData } from "../request";

const Topbar = ({ onTabClick }) => {
  const { token } = useContext(Context);
  const publishJson = async () => {
    try {
      await publishData(token);
      notification.success({ message: "Exported Successfully" });
    } catch (error) {
      const message =
        error?.response?.status === 403
          ? "You don't have permission to publish data points!"
          : "Couldn't export json";
      notification.error({ message });
    }
  };
  const items = [
    { label: "Data Points", key: "item-1", children: <List /> }, // remember to pass the key prop
    { label: "App Details", key: "item-2", children: <HomeContainer /> },
  ];
  const operations = (
    <Popconfirm
      title="Are you sure to publish data?"
      onConfirm={publishJson}
      okText="Yes"
      cancelText="No"
    >
      <Button type="primary" style={{ marginRight: "30px" }}>
        Publish
      </Button>
    </Popconfirm>
  );
  return (
    <>
      <Tabs
        items={items}
        onTabClick={onTabClick}
        tabBarExtraContent={operations}
        // style={{ padding: "10px 20px" }}
      />
    </>
  );
};
export default Topbar;
