import { Divider } from "antd";
import Title from "antd/lib/typography/Title";
import Carousel from "antd/lib/carousel";
import React from "react";
import YouTube from "react-youtube";

const YoutubePlayer = (props) => {
  const { videoId } = props;
  const opts = {
    height: "240",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      //   autoplay: 1,
    },
  };
  return <YouTube videoId={videoId} opts={opts} />;
};
const CarouselPreview = (items) => {
  return (
    <Carousel autoplay style={{ width: "50%" }}>
      {items.map((item) => {
        let returndata = JSON.stringify(item);
        if (item?.item_type && item?.item_url) {
          switch (item.item_type) {
            case "I":
              returndata = (
                <img width={"50%"} src={item.item_url} alt={item.item_url} />
              );
              break;
            case "V":
              returndata = (
                <video src={item.item_url} width={"100%"} controls />
              );
              break;
            case "VYT":
              returndata = <YoutubePlayer videoId={item.item_url} />;
              break;

            default:
              break;
          }
        }
        console.log(item);
        console.log(returndata);
        return returndata;
      })}
    </Carousel>
  );
};
const PreviewContainer = (props) => {
  const { data, title = "", width = "40vw" } = props;
  console.log(data);
  const renderTextorHtml = (data) => {
    return data.detail_html ? (
      <div dangerouslySetInnerHTML={{ __html: data.detail_html }} />
    ) : (
      data.detail_text
    );
  };
  return (
    <div>
      {title && (
        <>
          <Title level={2}>{data?.title || title}</Title>
          <Divider />
        </>
      )}
      {data?.details &&
        data.details.map((item) => {
          let returndata = JSON.stringify(item);
          if (item?.detail_type) {
            switch (item.detail_type) {
              case "P":
                returndata = <p>{renderTextorHtml(item)}</p>;
                break;
              case "H1":
                returndata = <h1> {renderTextorHtml(item)}</h1>;
                break;
              case "H2":
                returndata = <h2> {renderTextorHtml(item)}</h2>;
                break;
              case "H3":
                returndata = <h3> {renderTextorHtml(item)}</h3>;
                break;
              case "H4":
                returndata = <h4> {renderTextorHtml(item)}</h4>;
                break;
              case "H5":
                returndata = <h5> {renderTextorHtml(item)}</h5>;
                break;
              case "H6":
                returndata = <h6> {renderTextorHtml(item)}</h6>;
                break;
              case "I":
                returndata = (
                  <img
                    width={"100%"}
                    src={item.image_url}
                    alt={item.image_url}
                  />
                );
                break;
              case "V":
                returndata =
                  item.video_type === "youtube" ? (
                    <YoutubePlayer videoId={item.video_url} />
                  ) : (
                    <video src={item.video_url} width={"100%"} controls />
                  );
                break;
              case "A":
                returndata = (
                  <audio
                    src={item.audio_url}
                    style={{ width: "100%" }}
                    controls
                  />
                );
                break;
              case "G":
                returndata = CarouselPreview(item.items);
                break;

              default:
                break;
            }
          }
          console.log(returndata);
          return returndata;
        })}
    </div>
  );
};

export default PreviewContainer;
