import axios from "axios";
// const host = "http://localhost:1339";
// const token =
//   "Bearer d021eba1e7389ac00034a8c2266b81effc9c33fbb503e5390fa4069b3fc622260d132900de8c4ef098b3fe37a4669313d2fb945b749b691fb5253cbe68943c903f513052fea488c3ffb14e640e667b86f0c5efa6859338f3d5a019e3ded4c8fd86301f7ad901ea724d3d7c3d7064516ef6f596d7e5f41d8b5d845a3305a24eca";

export const get_dataTypes = (token) => {
  return axios.request({
    method: "GET",
    url: "/api/datatypes",
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  });
};

export const get_pages = (params, token) => {
  return axios.request({
    method: "GET",
    url: `/api/pages?filters[datatype][id][$eq]=${params.datatype}`,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  });
};

export const update_page = (id, body, token) => {
  return axios.request({
    method: "PUT",
    url: `/api/pages/${id}`,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    data: { data: body },
  });
};

export const delete_page = (id, token) => {
  return axios.request({
    method: "DELETE",
    url: `/api/page/softDelete/${id}`,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  });
};

export const update_dataType = (id, body, token) => {
  return axios.request({
    method: "PUT",
    url: `/api/datatypes/${id}`,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    data: { data: body },
  });
};

export const post_pages = (formData, token) => {
  return axios.request({
    method: "POST",
    url: `/api/pages`,
    data: { data: formData },
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  });
};

export const auth_login = (body, token) => {
  return axios.request({
    method: "POST",
    url: `/api/auth/local`,
    data: body,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  });
};

export const publishData = (token) => {
  return axios.request({
    method: "GET",
    url: `/api/exportJson`,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  });
  
};
