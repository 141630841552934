export const defaultschema = {
  title: "",
  description: "",
  type: "object",
  required: ["firstName", "lastName"],
  properties: {
    firstName: {
      type: "string",
      title: "First name",
      default: "Chuck",
    },
    lastName: {
      type: "string",
      title: "Last name",
    },
    age: {
      type: "integer",
      title: "Age",
    },
    Interested: {
      type: "boolean",
      title: "Interested",
    },
    militaryCareer: {
      type: "object",
      properties: {
        rank: {
          type: "string",
        },
        unit: {
          type: "string",
        },
      },
    },
    skills: {
      type: "array",
      items: {
        type: "string",
      },
    },
    bio: {
      type: "string",
      title: "Bio",
    },
    telephone: {
      type: "string",
      title: "Telephone",
      minLength: 10,
    },
    items: {
      type: "array",
      items: {
        type: "object",
        anyOf: [
          {
            properties: {
              foo: {
                type: "string",
              },
            },
          },
          {
            properties: {
              bar: {
                type: "string",
              },
            },
          },
        ],
      },
    },
  },
};
export const uidefaultschema = {
  "ui:submitButtonOptions": {
    norender: true,
  },
};

export const editorOptions = {
  options: [
    "inline",
    "blockType",
    "fontSize",
    "fontFamily",
    "list",
    "textAlign",
    "colorPicker",
    "link",
    "emoji",
    "remove",
    "history",
  ],
};
