import React, { useContext, useEffect, useState } from "react";
import { Button, Popconfirm, Space, Table, Tabs } from "antd";
import { delete_page, get_pages } from "../request";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import Context from "../Context";

function Pages(props) {
  const { token } = useContext(Context);
  const { pages, loading, setPages, setEditId, setPreview } = props;

  const deleteDatatype = async (dtid) => {
    delete_page(dtid, token);
    setPages((formData) => formData.filter((item) => item.id !== dtid));
  };

  const columns = [
    {
      title: "Title",
      key: "title",
      render: (value, record) => `${record.attributes.title}`,
    },
    {
      title: "Updated At",
      width: "30%",
      key: "updatedAt",
      render: (value, record) =>
        `${new Date(record.attributes.updatedAt).toLocaleString()}`,
    },
    {
      title: "Action",
      key: "action",
      width: "20%",
      render: (value, record) => (
        <Space direction="horizontal" size={8}>
          <Button icon={<EditOutlined />} onClick={() => setEditId(record)} />
          <Button icon={<EyeOutlined />} onClick={() => setPreview(record)} />
          <Popconfirm
            title="Are you sure to delete this page?"
            onConfirm={() => deleteDatatype(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <React.Fragment>
      <div className="list-container">
        <Table loading={loading} dataSource={pages} columns={columns} />
      </div>
    </React.Fragment>
  );
}
export default Pages;
