import React, { useContext, useEffect, useRef, useState } from "react";
import Form from "@rjsf/core";
import { diff } from "json-diff";
import { Button, Input, notification, Select, Typography } from "antd";
import validator from "@rjsf/validator-ajv8";
import "./Preview.scss";
import Context from "../Context";
import * as customWidgets from "../Customs";
import PreviewContainer from "./PreviewContainer";
import { update_page } from "../request";
const { Title } = Typography;
const widgets = {
  HTMLWidget: customWidgets.HTMLWidget,
  TextAreaWidget: customWidgets.TextAreaWidget,
  DateTimeWidget: customWidgets.DateTimeWidget,
};


const templates = {
  ButtonTemplates: { RemoveButton: customWidgets.RemoveButton },
  ArrayFieldTemplate: customWidgets.ArrayFieldTemplate,
  // ObjectFieldTemplate: customWidgets.ObjectFieldTemplate,
};

const JSON_DATA_MAPPER = {
  English: "JSON_ENG",
  Gujarati: "JSON_GUJ",
  Hindi: "JSON_HIN",
};

function Preview(props) {
  const { token } = useContext(Context);
  const { datatype, data } = props;
  const previewform = useRef();
  const [formData, setFormData] = useState({
    JSON_ENG: {},
    JSON_GUJ: {},
    JSON_HIN: {},
  });
  const [titleFormData, setTitleFormData] = useState("");
  const [language, setLanguage] = useState("English");
  const [schema, setSchema] = useState({});
  const [uischema, setUISchema] = useState({});

  useEffect(() => {
    if (data.attributes?.title) {
      setTitleFormData(data.attributes?.title);
      setFormData({
        JSON_ENG: data.attributes.JSON_ENG,
        JSON_GUJ: data.attributes.JSON_GUJ,
        JSON_HIN: data.attributes.JSON_HIN,
      });
    }
  }, [data]);

  useEffect(() => {
    if (datatype.attributes?.jsonschema) {
      setSchema(datatype.attributes?.jsonschema);
      setUISchema(datatype.attributes?.uischema);
    }
  }, [datatype]);

  const onSubmit = () => {
    try {
      // if (!previewform.current.validateForm()) {
      //   return;
      // }
      if (!titleFormData?.length) {
        notification.error({
          message: "Please enter Title!",
        });
        return;
      }
      updateData();
    } catch (error) {
      console.log(error);
      return;
    }
  };
  const updateData = async () => {
    const responsedata = await update_page(
      data.id,
      {
        ...formData,
        title: titleFormData,
      },
      token
    );
    responsedata.status === 200
      ? notification.success({
          message: "Updated successfully",
        })
      : notification.error({
          message: "Something failed",
        });
  };
  return (
    <React.Fragment>
      <div className="preview-header">
        <Title>Language</Title>
        <Select
          value={language}
          style={{
            width: "20%",
          }}
          onChange={(e) => setLanguage(e)}
          options={[
            {
              value: "English",
              label: "English",
            },
            {
              value: "Gujarati",
              label: "Gujarati",
            },
            {
              value: "Hindi",
              label: "Hindi",
            },
          ]}
        />
      </div>
      <div className="preview-outer-container">
        <div className="preview-container">
          <div className="preview-header">
            <Title level={4}>Title</Title>
            <Input
              maxLength={50}
              style={{
                width: "50%",
              }}
              value={titleFormData}
              onChange={(e) => setTitleFormData(e.target.value)}
            />
          </div>
          <Title>{language}</Title>
          <Form
            className="rjsfform"
            ref={previewform}
            schema={schema}
            uiSchema={uischema}
            autocomplete="on"
            formData={formData[JSON_DATA_MAPPER[language]]}
            validator={validator}
            formContext={{ hideAll: false }}
            widgets={widgets}
            templates={templates}
            onChange={(data) => {
              console.log(JSON_DATA_MAPPER[language]);
              setFormData({
                ...formData,
                [JSON_DATA_MAPPER[language]]: data.formData,
              });
            }}
          />
        </div>
        <div className="preview-container mobile-width">
          <PreviewContainer
            data={formData[JSON_DATA_MAPPER[language]]}
            title={titleFormData}
          />
        </div>
      </div>
      <Button type="primary" className="submit-btn" onClick={onSubmit}>
        Submit
      </Button>
    </React.Fragment>
  );
}
export default Preview;
