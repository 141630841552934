import React, { useContext } from "react";
import "./Login.scss";
import { Form, Input, Button, Checkbox, Space, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Context from "../Context";
import { auth_login } from "../request";

const LoginForm = () => {
  const { token, updateContext, setCookie } = useContext(Context);
  const onFinish = async (values) => {
    console.log("Received values of form: ", values);
    const data = await auth_login(values, token);
    let expires = new Date();
    expires.setTime(expires.getTime() + 18000000);
    setCookie("token", "Bearer " + data.data.jwt, {
      path: "/",
      expires,
    });
    data.status === 200
      ? updateContext({ isLogin: true })
      : notification.error("Auth Failed");
  };

  return (
    <Space
      direction="horizontal"
      style={{
        width: "100%",
        height: "80vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="identifier"
          rules={[
            {
              required: true,
              message: "Please input your Email!",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your Password!",
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Button type="link" className="login-form-forgot">
            Forgot password
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
};
export default LoginForm;
