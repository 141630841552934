import { useState } from "react";
import { CookiesProvider, useCookies } from "react-cookie";
import "./App.scss";
import Context from "./Context";
import Login from "./Login/Login";
import Topbar from "./Topbar/Topbar";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [context, setContext] = useState({ isLogin: false, token: "" });
  const [cookies, setCookie] = useCookies(["token"]);

  const updateContext = (values) => {
    setContext({ ...context, ...values });
  };
  return (
    <CookiesProvider>
      <Context.Provider
        value={{
          ...context,
          updateContext,
          ...cookies,
          setCookie,
        }}
      >
        <div className="App">
          {!context.isLogin && !cookies.token ? <Login /> : <Topbar />}
        </div>
        ;
      </Context.Provider>
    </CookiesProvider>
  );
}

export default App;
