import React from "react";
import dayjs from "dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker from "antd/lib/date-picker/generatePicker";

const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
const advanced = require("dayjs/plugin/advancedFormat");

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advanced);

const DatePicker = generatePicker(dayjsGenerateConfig);

const DATE_PICKER_STYLE = {
  width: "100%",
};

const DateTimeWidget = ({
  disabled,
  formContext,
  id,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  readonly,
  value,
}) => {
  const { readonlyAsDisabled = true } = formContext;

  const handleChange = (nextValue) =>
    onChange(nextValue && nextValue.toISOString());

  const handleBlur = () => onBlur(id, value);

  const handleFocus = () => onFocus(id, value);

  const getPopupContainer = (node) => node.parentNode;

  return (
    <DatePicker
      disabled={disabled || (readonlyAsDisabled && readonly)}
      getPopupContainer={getPopupContainer}
      id={id}
      name={id}
      onBlur={!readonly ? handleBlur : undefined}
      onChange={!readonly ? handleChange : undefined}
      onFocus={!readonly ? handleFocus : undefined}
      showTime
      style={DATE_PICKER_STYLE}
      value={value && dayjs(value).tz("Asia/Kolkata")}
    />
  );
};

export default DateTimeWidget;
